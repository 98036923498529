import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { Link } from "gatsby";

interface BlogTagAndDateProps {
    tags: Array<string>;
    tagSlugs: Array<string>;
    date: string
}

const NegMarginGridItem = styled(Grid)`
&:first-of-type {
    margin-left: -10px;
}
`;

const BlogTagAndDate: FunctionComponent<BlogTagAndDateProps> = (props) => {
    return (
        <Box pb={2} minHeight={52}>
        <Typography variant="overline" align="left">
            <Grid container justify="flex-start" alignItems="center">
                {props.tags && <>
                    {props.tags.slice(0, 2).map((x,i) => <NegMarginGridItem key={x} item>
                        <Button color="primary" key={x} to={`/blog/tags/${props.tagSlugs[i]}`} component={Link}>{x}</Button>
                    </NegMarginGridItem>)}
                </>}
                <Grid item xs={12} sm={6}>
                    {props.date}
                </Grid>
            </Grid>
        </Typography>
        </Box>
    );
}

export default BlogTagAndDate;
import React, { FunctionComponent } from "react";
import { RoundPaper, BottomRoundedImg, UnderlineOnHoverLink } from "./styledComponents";
import { Grid, Box, Typography } from "@material-ui/core";
import styled from "@emotion/styled";
import BlogTagAndDate from "./blog-tag-and-date";
import { Link } from "gatsby";

interface BlogListProps {
    posts: Array<BlogPost>;
}

export interface BlogPost {
    excerpt: string;
    fields: {
        slug: string;
        tags: Array<string>;
    }
    frontmatter: {
        title: string;
        tags: Array<string>;
        publish_date: string;
        primary_image: import("gatsby-source-filesystem").FileSystemNode;
    }
}

const CappedRoundedImg = styled(BottomRoundedImg)`
        height: 250px;
    `;

const HundypPaper = styled(RoundPaper)`
        height: 100%;
    `;

const HundypGrid = styled(Grid)`
        height: 100%;
    `;

const EndItem = styled(Grid)`
        align-self: flex-end;
    `;

const BlogList: FunctionComponent<BlogListProps> = (props) => {
    return (<Grid container justify="center" alignItems="stretch" spacing={2}>
        {props.posts.map(p => <Grid key={p.fields.slug} item md={4}>
            <HundypPaper elevation={4}>
                <HundypGrid container>
                    <Grid item xs={12}>
                        <Box px={4} pt={2} pb={6} >
                            <BlogTagAndDate tags={p.frontmatter.tags} tagSlugs={p.fields.tags} date={p.frontmatter.publish_date} />
                            <Typography variant="h3" gutterBottom={true}><UnderlineOnHoverLink to={p.fields.slug}>{p.frontmatter.title}</UnderlineOnHoverLink></Typography>
                            <Typography variant="body2">{p.excerpt}</Typography>
                        </Box>
                    </Grid>
                    <EndItem item xs={12}>
                        <Link to={p.fields.slug}><CappedRoundedImg fluid={(p.frontmatter.primary_image.childImageSharp as any).fluid} /></Link>
                    </EndItem>
                </HundypGrid>
            </HundypPaper>
        </Grid>)}
    </Grid>);
}

export default BlogList;